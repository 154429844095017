import React from "react"

import Layout from "../components/layout"
import styled from 'styled-components'
import {Link} from 'gatsby'

const Container = styled.div`
  margin: auto auto;
  text-align: center;

  a {
    color: white;
  }
`

const NotFoundPage = () => (
  <Layout>
    <Container>
      <h1>PAGE NOT FOUND</h1>
      <p>You can go back to <Link to="/">Home</Link></p>
    </Container>
  </Layout>
)

export default NotFoundPage
